<template>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <!-- popup -->
  <div
    class="popup-container-top align-items-center"
    v-show="blnshowPopup.blnshow == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strAltMsg)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t(strButtonSubmit)"
        :strButtonCancel="$t(strButtonCancel)"
        @confirm="confirmPopup($event)"
        classIcon="text-secondary fill-secondary"
        :Cancel="
          strButtonSubmit == '886' || strButtonSubmit == '214' ? true : false
        "
        :alertDepartment="false"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" v-show="blnshowPopup.blnshow == true"></div>
    </transition>
  </div>
  <div>
    <div>
      <Header
        icon="campusOutline"
        :headerName="$t('สิทธิ์เข้าใช้งาน')"
        :breadcrumbname="
          action == 'Insert'
            ? $t('เพิ่มสิทธิ์เข้าใช้งาน')
            : '' || action == 'Update'
            ? $t('แก้ไขสิทธิ์เข้าใช้งาน')
            : ''
        "
        :btnName="$t('เพิ่มสิทธิ์เข้าใช้งาน')"
        class="mb-3"
        @onInput="openform($event)"
        :breadcrumb="blnOpenform == true ? '' : 'd-none'"
        :helperHeader="
          blnOpenform == true ? $t('กรุณากรอกข้อมูลสิทธิ์เข้าใช้งาน') : ''
        "
        :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
        :rightHeader="blnOpenform == true ? 'd-none' : ''"
        :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
        @showFilter="openFilter($event)"
        id="header"
        ListCampusClass="d-none"
        :Searchname="$t('ค้นหาสิทธิ์เข้าใช้งาน')"
        :classAutoSearch="
          ListDataTable.data.length > 0 ? '' : 'input-group-disable'
        "
        :tooltipSearchMsg="
          ListDataTable.data.length > 0 ? null : 'ไม่มีข้อมูลสิทธิ์การใช้งาน'
        "
        @onSearch="onSearch($event)"
        @onClean="clean()"
        :blnOpenform="blnOpenform"
        :showFilter="blnOpenFilter"
        :filterActive="{
          blnOpenform: blnOpenFilter,
          useFilter: activeFiltersSelected.length == 0 ? false : true,
          disableFilter: ListDataTable.data.length > 0 ? false : true,
        }"
        ref="header"
      />
    </div>
    <div class="popup-container" v-show="blnOpenFilter == true">
      <transition name="zoom">
        <Filter
          v-show="blnOpenFilter == true"
          @appectFilter="filteredData($event)"
          :listDataFilter="listAssignRoleFilter"
          @Cancel="blnOpenFilter = !blnOpenFilter"
          :listActiveFiltersSelected="activeFiltersSelected"
          :blnHaveDecision="false"
          :blnOpenFilter="blnOpenFilter"
          ref="filter"
          btnFix="btn-group-fix"
          classFilter="filter-fix"
        />
      </transition>
      <transition name="fade">
        <div
          class="overlay"
          v-show="blnOpenFilter == true"
          @click="blnOpenFilter = false"
        ></div>
      </transition>
    </div>
    <div v-show="blnOpenform == false">
      <Table
        :progressScore="progress"
        :nametable="$t('สิทธิ์เข้าใช้งาน')"
        :ListDataTable="ListDataTableClone"
        firstSort="intRoleStatus"
        secondSort=""
        :classTable="'shadow-sm p-4'"
        :blnshowicon="true"
        :showDisplay="false"
        :blnshowconfig="false"
        :blnHideConfig="false"
        :blnUseDeleteButton="false"
        statusActiveName="61"
        statusInactiveName="62"
        @editAndDelete="editAndDelete($event)"
        ref="table"
      />
    </div>
    <div v-if="blnOpenform">
      <formAssignRole :action="action" :roleID="strRoleID" />
    </div>
  </div>
</template>

<script>
import formAssignRole from "@/views/systemadmin/assignRole/formAssignRole.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import Filter from "@/components/filter/filter.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
import AltStatus from "@/components/alert/alertStatus.vue";

import AdsInventories from "@/typescript/inventory";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import APIRole from "@/typescript/urlapi/adminsystem/APIRole";
import cookiesPath from "@/typescript/urlapi/cookiesPath.ts";
import VueCookies from "vue-cookies";

import Enumerable from "linq";
export default {
  components: {
    formAssignRole,
    Header,
    Table,
    Filter,
    AlertPopup,
    AltStatus,
  },
  data() {
    return {
      strSMSGsuccess: "1027",
      blnAltStatus: false, //Alt Status Success

      strAltMsg: "885",
      strButtonSubmit: "886",
      strButtonCancel: "35",
      blnshowPopup: { blnshow: false, state: "" },

      blnOpenform: false,
      blnOpenFilter: false,
      action: "Insert",

      progress: 0,
      ListDataTable: {
        dataFields: [
          {
            field: "intRoleStatus",
            head: "19",
          },
          {
            field: "strRolenameTH",
            head: "ชื่อสิทธิ์เข้าใช้งาน",
          },
          {
            field: "strRolenameEN",
            head: "ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)",
          },
          {
            field: "strUsergroup",
            head: "กลุ่มผู้ใช้งาน",
          },
          {
            field: "intAssignrole",
            head: "จำนวนผู้ได้รับสิทธิ์",
          },
        ],
        defaultField: [
          {
            field: "intRoleStatus",
            head: "19",
          },
          {
            field: "strRolenameTH",
            head: "ชื่อสิทธิ์เข้าใช้งาน",
          },
          {
            field: "strRolenameEN",
            head: "ชื่อสิทธิ์เข้าใช้งาน (อังกฤษ)",
          },
          {
            field: "strUsergroup",
            head: "กลุ่มผู้ใช้งาน",
          },
          {
            field: "intAssignrole",
            head: "จำนวนผู้ได้รับสิทธิ์",
          },
        ],
        selectedListExcept: [],
        total: 1,
        data: [
          // {
          //   strID: "1",
          //   intRoleStatus: 1,
          //   intUsergroupID: 1,
          //   strRolenameTH: "นักศึกษาปริญญาตรี",
          //   strRolenameEN: "Undergraduate student",
          //   strUsergroupTH: "นักศึกษา",
          //   strUsergroupEN: "Student",
          //   intAssignrole: 10,
          //   delete: false,
          // },
        ],
      },
      ListDataTableClone: {},
      ListDummy: [],
      listAssignRoleFilter: [
        {
          list: [
            { name: "19" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "61" }, //open
                { id: 0, lang: "62" }, //close
              ],
            },
            { state: "intRoleStatus" },
          ],
        },
        {
          list: [
            { name: "448" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "450" }, //นักศึกษา
                { id: 2, lang: "451" }, //เจ้าหน้าที่
                { id: 3, lang: "คนภายนอก" }, //คนภายนอก
                { id: 69, lang: "นักศึกษาแพทย์" }, //นักศึกษาแพทย์
                { id: 68, lang: "1" }, //Admin
              ],
            },
            { state: "intUserGroupID" },
          ],
        },
      ],
      activeFiltersSelected: [],
      strkeywords: "",
      strSearchField: [
        "strRolenameTH",
        "strRolenameEN",
        "strUsergroupTH",
        "strUsergroupEN",
        "intAssignrole",
      ],
      strRoleID: null,
      strDeleteRoleID: null,
    };
  },
  mounted() {
    this.processLoadingData();
  },
  methods: {
    confirmPopup(event) {
      if (event == true) {
        // ลองใหม่/ยกเลิก  //ใช่
        if (this.strButtonSubmit == "886" || this.strButtonSubmit == "214") {
          if (this.blnshowPopup.state == "Getrole") {
            this.processLoadingData();
          }
        } else {
          // รับทราบ
          if (this.strAltMsg == "section expired") {
            localStorage.removeItem("UserInfo");
            localStorage.removeItem("language");
            VueCookies.remove("UserInfo", "/", cookiesPath);
            VueCookies.remove("moduleAccess", "/", cookiesPath);
            //ไปยังหน้าแรก
            setTimeout(() => {
              window.location.href =
                cookiesPath == "localhost"
                  ? "http://localhost:8080"
                  : "https://cessystem.wu.ac.th";
            }, 300);
          }
        }
        this.blnshowPopup.blnshow = false;
      } else {
        this.blnshowPopup.blnshow = false;
      }
    },
    async processLoadingData() {
      this.progress = 0;
      await this.fetchGetrole();
      this.ListDataTableClone = JSON.parse(JSON.stringify(this.ListDataTable));
      this.ListDummy = JSON.parse(JSON.stringify(this.ListDataTable));
      this.$refs.header.onClear();
      this.processData().then(() => {
        setTimeout(() => {
          this.progress = 100;
        }, 1000);
      });
    },
    async processData() {
      try {
        //จัดเรียงข้อมูล
        setTimeout(() => {
          if (Array.isArray(this.ListDataTable.data)) {
            this.ListDataTable.data = Enumerable.from(this.ListDataTable.data)
              .orderByDescending((r) => r.intStatus)
              //.thenBy((r) => r.intMaxSize)
              .toArray();
          }
        }, 1000);

        setTimeout(() => {
          this.$refs.table.onClean();
          this.$refs.table.setSelectedList(this.ListDataTable.defaultField);
        }, 100);
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    openform(e) {
      this.action = "Insert";
      this.blnOpenform = e;
      if (this.blnOpenform == false) {
        this.processLoadingData();
      }
    },
    openFilter(e) {
      this.blnOpenFilter = e;
    },
    onSearch(e) {
      this.strkeywords = e;
      this.$refs.table.SearchListDataTable(
        this.strkeywords,
        this.strSearchField
      );
    },
    // รับค่าจาก component filter
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;
      this.filter(activeFilters, this.ListDummy.data);
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListDataTableClone.data = ListDummy.slice();

      // ตรวจสอบ usergroup
      let listusergroup = [];
      this.ListDataTable.data.forEach((element) => {
        activeFilters.forEach((filter) => {
          console.log(element[filter.state], filter);
          if (
            filter.state === "intUserGroupID" &&
            element[filter.state] == filter.id
          ) {
            console.log(element);
            listusergroup.push(element);
          }
        });
      });
      if (listusergroup.length === 0) {
        // ตรวจสอบว่ามี intRoleStatus ใน activeFilters หรือไม่
        const hasRoleStatusFilter = activeFilters.some(
          (filter) => filter.state === "intRoleStatus"
        );
        // ถ้ามี intRoleStatus ให้กรองข้อมูลด้วย intRoleStatus
        if (hasRoleStatusFilter) {
          const filteredData = this.ListDataTable.data.filter((element) =>
            activeFilters.some(
              (filter) =>
                filter.state === "intRoleStatus" &&
                element[filter.state] == filter.id
            )
          );
          this.ListDataTableClone.data = filteredData;
        } else {
          // ถ้าไม่มี intRoleStatus ให้ใช้ this.ListDataTable.data เลย
          this.ListDataTableClone.data = this.ListDataTable.data;
        }
      } else {
        // ตรวจสอบว่ามี intRoleStatus ใน activeFilters หรือไม่
        const hasRoleStatusFilter = activeFilters.some(
          (filter) => filter.state === "intRoleStatus"
        );

        // ถ้ามี intRoleStatus ให้กรองข้อมูลด้วย intRoleStatus
        if (hasRoleStatusFilter) {
          const filteredData = listusergroup.filter((element) =>
            activeFilters.some(
              (filter) =>
                filter.state === "intRoleStatus" &&
                element[filter.state] == filter.id
            )
          );
          this.ListDataTableClone.data = filteredData;
        } else {
          // ถ้าไม่มี intRoleStatus ให้ใช้ listusergroup เลย
          this.ListDataTableClone.data = listusergroup;
        }
      }
      console.log(listusergroup);

      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListDataTableClone.data.length,
          this.ListDataTableClone.data
        );
      }
      this.$refs.table.SearchListDataTable(
        this.strkeywords,
        this.strSearchField
      );
      this.blnOpenFilter = false;

      this.ListDummy.data = ListDummy;
    },
    editAndDelete(e) {
      this.action = "Update";
      if (e.state == "delete") {
        this.strDeleteRoleID = e.id;
        this.fetchDeletetRole();
      } else {
        this.action = "Update";
        this.strRoleID = e.id;
        this.blnOpenform = true;
      }
    },

    // -------- api ---------------------
    async fetchGetrole() {
      try {
        const res = await axios.get(urlapi + APIRole.role);
        console.log(res);
        if (res.status == 200 && res.data.blnStatus == true) {
          const dataAPI = res.data.data.listData;
          const dataAPIdataFields = res.data.data.dataFields;
          const dataAPIdefaultField = res.data.data.defaultField;
          this.ListDataTable.dataFields = dataAPIdataFields;
          this.ListDataTable.defaultField = dataAPIdefaultField;
          // แปลงข้อมูล
          if (dataAPI) {
            this.ListDataTable.data = dataAPI.map((item) => ({
              strID: item.intRoleID, // แปลง ID เป็น string
              intRoleStatus: item.blnStatus ? 1 : 0,
              strRolenameTH: item.strRoleNameTH,
              strRolenameEN: item.strRoleNameEN,
              intUserGroupID: item.intUserGroupID,
              strUsergroupTH: item.strUserGroupNameTH,
              strUsergroupEN: item.strUserGroupNameEN,
              intAssignrole: item.intNumUserRole,
              delete:
                item.blnDefaultRole == false && item.intNumUserRole === 0
                  ? true
                  : false,
            }));
          }
        } else if (res.status == 204) {
          this.ListDataTable.data = [];
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("Getrole", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("Getrole", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "Getrole", "get");
      }
    },
    async fetchDeletetRole() {
      try {
        const res = await axios.delete(
          urlapi + APIRole.delete_role + "/" + this.strDeleteRoleID
        );
        console.log(res);
        if (res.status == 204) {
          this.blnAltStatus = true;
          this.strSMSGsuccess = "28";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.processLoadingData();
        } else if (res.status == 500) {
          this.strButtonSubmit = "886";
          this.onAPIError("Getrole", "885");
        } else {
          this.strButtonSubmit = "1288";
          this.onAPIError("Getrole", "1431");
        }
      } catch (error) {
        this.onCatchAPIError(error, "Getrole", "get");
      }
    },
    // ------------------------------------------------------------------------------------------------
    // alert API no catch
    onAPIError(strNameAPI, strMSG) {
      setTimeout(() => {
        this.blnshowPopup.blnshow = true;
        this.blnshowPopup.state = strNameAPI;
        this.strAltMsg = strMSG;
      }, 200);
    },
    // alert API on catvh
    onCatchAPIError(error, strNameAPI, action) {
      let MSG = "";
      console.log(error);
      if (action == "insert") {
        MSG = this.$t("1428", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "update") {
        MSG = this.$t("1430", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "delete") {
        MSG = this.$t("1429", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else if (action == "get") {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      } else {
        MSG = this.$t("1431", {
          LockContact: this.$t(AdsInventories.LockContact),
        });
      }
      if (error == "Error: Request failed with status code 400") {
        // ค่าที่ส่งมาไม่ถูกต้อง
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg =
            // "ค่าที่ส่งมาไม่ถูกต้อง"
            MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 401") {
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "section expired";
        }, 200);
      } else if (error == "Error: Request failed with status code 404") {
        // ไม่ได้ส่งค่ามา
        setTimeout(() => {
          this.strButtonSubmit = "1288";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = MSG;
        }, 200);
      } else if (error == "Error: Request failed with status code 500") {
        // console.log("500", strNameAPI);
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else if (error == "Error: Network Error") {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      } else {
        setTimeout(() => {
          this.strButtonSubmit = "886";
          this.strButtonCancel = "215";
          this.blnshowPopup.blnshow = true;
          this.blnshowPopup.state = strNameAPI;
          this.strAltMsg = "885";
        }, 200);
      }
    },
  },
};
</script>

<style>
</style>